@font-face {
  font-family: "Arial Nova";
  src: local("Arial Nova"), url("../public/fonts/ArialNova.ttf");
  font-weight: normal;
  font-style: normal;
}

.order-items-specification-width{
  width: 300px;
}

.amount-width{
  width: calc(100% - 300px)
}

.p-galleria .p-galleria-thumbnail-container {
  min-width: 230px;
}

/* CustomScrollbar.css */
.custom-scrollbar {
  position: relative; /* Ensure the scrollbar respects its parent */
  overflow-y: auto;
  scrollbar-width: thin; /* "auto" or "thin" for Firefox */
  scrollbar-color: #ceb48e transparent; /* thumb and track color */
}


/* Target scrollbar element with vendor prefixes */
.custom-scrollbar::-webkit-scrollbar,
.custom-scrollbar::-moz-scrollbar,
.custom-scrollbar::-ms-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar::-moz-scrollbar-track,
.custom-scrollbar::-ms-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar::-moz-scrollbar-thumb,
.custom-scrollbar::-ms-scrollbar-thumb {
  background-color: #ceb48e;
  border-radius: 8px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover,
.custom-scrollbar::-moz-scrollbar-thumb:hover,
.custom-scrollbar::-ms-scrollbar-thumb:hover {
  background-color: #ceb48e; /* Change color on hover */
}

/* Hide scrollbar buttons (arrows) with vendor prefixes */
.custom-scrollbar::-webkit-scrollbar-button,
.custom-scrollbar::-moz-scrollbar-button,
.custom-scrollbar::-ms-scrollbar-button {
  display: none;
}




::-webkit-scrollbar-track{
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #978f8c; /* Adjust thumb color (optional) */
  border-radius: 10px; /* Rounded corners for the thumb (optional) */
}
* {
  -webkit-tap-highlight-color: transparent;
}
.popup-alert {
  position: fixed;
  top: 115px;
  left: 50%;
  width: 35%;
  transform: translate(-50%, -50%);
  background-color: #f0e5d4;
  padding: 20px;
  border: 1px solid #3a3432;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #3a3432;
  text-align: center;
}

.profile-navigate {
  padding: 0px 50px;
  position: relative;
  width: 200px;
  height: 100%;
  min-height: calc(100vh - 80px);
  background-color: #f0e5d4;
}
.profile-input-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 550px;
}

.newsletter-text {
  width: 550px;
}

.confirmation-width {
  width: 350px;
}

.profile-navigate-options {
  position: fixed;
}

.profile-navigate button {
  background-color: transparent;
  border: none;
  color: #3a3432;
  border-bottom: 1px solid transparent;
  font-size: 16px;
  display: flex;
  justify-content: start;
}
.home-page a {
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.active {
  font-weight: bold;
  border: none;
}

.closing-tab {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #3a3432;
}
.closing-tab:hover {
  background-color: #3a3432;
  color: #f0e5d4;
}
.alert {
  color: #df4930;
}

.shopping-cart-item {
  width:55%;
  margin-right: 20px;
}

.shopping-cart-summary {
  width:30%;
  min-width: 370px;
  height: fit-content;
}

::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}


.order-detail-form {
  width: 800px;
  padding: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.order-detail-line {
  justify-content: space-between;
}

.order-sumup {
  padding: 30px;
  width: 450px;
  background-color: #f0e5d4;
}


.transparent {
  background-color: transparent;
  border: 1px solid #ced4da;
  color: #3a3432;
}

.dark {
  background-color: #3a3432;
  border: 1px solid #3a3432;
  color: #f0e5d4;
}

.light {
  background-color: #f0e5d4;
  border: 1px solid #f0e5d4;
  color: #3a3432;
  border-color: #f0e5d4;
}

.dialog-min-width{
  width: 50vw;
}

.btn {
  cursor: pointer;
  font-size: 15px;
  padding: 5px 40px;
  border-radius: 5px;
  width: 100%;
}

.p-sidebar .p-sidebar-content {
  padding: 0px;
}

.m-l-cancel{
  margin-left: 1rem;
}

.alert-message {
  position: fixed; /* Position outside normal document flow */
  top: 0; /* Place at the top of the viewport */
  left: 0; /* Align to the left of the viewport (optional) */
  width: 100%; /* Span the entire width */
  z-index: 999; /* Ensure it's on top of other elements */
  /* Other styles for background color, padding, etc. */
}

.bottom-items {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  padding-bottom: 10px;
  width: 100%; /* Optionally, set the width to 100% to span the entire width of the page */
  /* Add any additional styling as needed */
}

.order-summary-wrapper {
  margin-bottom: 0px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.filter-sidebar {
  display: none;
}

.p-sidebar .p-sidebar-header {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-right: 10px;
}

.button-container {
  width: 250px;
  margin-left: auto;
  margin-right: 20px;
}

.p-button {
  color: #f0e5d4;
  background: #3a3432;
  border: 1px solid #3a3432;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 30px;
}
.p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
  height: 2rem;
  width: 2rem;
  z-index: 10;
}

body {
  color:black;
  font-family: "Arial Nova";
  font-size: 17px;
  z-index: 1;
  overflow: hidden;
}

/* 
.content-padding {
  padding-top: 80px;
} */

textarea {
  font-family: "Arial Nova", sans-serif;
  font-size: 15px;
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #eae6e3;
  border-color: #eae6e3;
  color: #3a3432;
}

.delete {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: white;
  border: 2px solid white;
  padding: 5px;
}

.delete:hover {
  border: 2px solid #3a3432;
}

.card-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
}

.order-by {
  display: flex;
  margin-right: 20px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  padding-bottom: 10.5px;
}

.order-by:hover,
.order-by:focus {
  cursor: pointer;
  color: #3a3432;
  border-bottom: 2px solid #3a3432; /* Set the color and style of the underline */
  padding-bottom: 10.5px; /* Adjust the spacing between text and underline */
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 1000%);
}

.pointer:hover {
  cursor: pointer;
}



ul {
  list-style: none;
  margin:0px
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #f0e5d4;
}

.p-sidebar-close:focus {
  box-shadow: none;
}

.p-rating-item.p-rating-item-active:focus {
  border: none;
  box-shadow: none;
}

.p-galleria .p-galleria-thumbnail-container {
 
  background: white;
  padding: 1rem 0.25rem;
}
.p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  color: #3a3432;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  color: #3a3432;
}

.p-dropdown-label {
  font-family: "Arial Nova";
  font-size: 15px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 15px;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icons:focus {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar-close:focus .p-sidebar-icons:focus .p-link:focus {
  border: none;
}

.p-sidebar-close:active .p-sidebar-icons:active .p-link:active {
  border: none;
}

.p-inputtext {
  font-size: 15px;
}

.p-dropdown-item {
  font-size: 15px;
}

.p-dropdown-panel .p-dropdown-items {
  margin: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #3a3432;
  background: #f0e5d4;
}

li.p-dropdown-item:hover {
  background-color: transparent;
}

.p-dropdown:hover {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.p-inputwrapper-focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.review-form {
  width: 60vw;
  height: fit-content;
  background-color: #f0e5d4;
  padding: 20px 40px;
  border-radius: 5px;
}

.review-overview {
  background-color: #f0e5d4;
  padding: 30px 50px;
  margin-top: 30px;
  width: 50vw;
}

.review-form input[type="text"] {
  width: 100%;
}

.review-form textarea {
  width: 100%;
  height: 200px;
  font-size: 15px;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
}

.review-send-wrapper {
  height: calc(90vh - 80px);
  text-align: center;
}

.review-send .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.review-send .text {
  font-size: 17px;
}

.align-amount{
  justify-content: end;
  text-align: end;
}

.align-packeta-bnt{
  justify-content: end;
  text-align: end;
}

.packeta-wraper-width{
  width: 360px;
}


.review-send {
  background-color: #eae6e3;
  border: 5px solid #3a3432;
  padding: 30px 50px;
  color: #3a3432;
  width: 40vw;
}

textarea {
  box-sizing: border-box;
  resize: none;
  outline: none;
  overflow: auto;
  font-family: "Arial Nova";
}

.shopping-cart-summary input[type="text"]{
  border-bottom: 1px solid #3a3432;
}

.document {
  color: #3a3432;
  line-height: 1.6;
  margin: 20px;
  width: 80%;
}

.document h4{
  margin: 5px 0px;
}

.document h1 {
  text-align: center;
  color: #3a3432;
}

.document .terms section{
  margin-top: 30px;
}

.document h2 {
  color: #3a3432;
 margin:20px 0px;
  }

.document h3 {
  color: #3a3432;
  margin-top: 15px;
}

.document p {
  margin: 10px 0;
}

.document ul {
  list-style-type: disc;
  margin-left: 20px;
}

.document ul li {
  margin: 5px 0;
}

.document address {
  font-style: normal;
  margin: 10px 0;
}

.document a {
  color: #3a3432;
  text-decoration: none;
}

.document a:hover {
  text-decoration: underline;
}


.order-details input[type="checkbox"]:before{
  left: 3px;
  top:-2px;
  font-size: 15px;
}

.p-datepicker table td > span.p-highlight {
  color: #495057;
  background: #f0e5d4;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #eae6e3;
  color: #495057;
  border-color: transparent;
}

.p-button.p-button-icon-only {
  width: 30px;
  height: 30px;
  padding: 7px;
  margin-left: 10px;
}


.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: 0 0 0 0.2rem #f0e5d4;
  border:none;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border:none;
}


input[type="text"],
input[type="number"],
input[type="password"] {
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 30px;
  border: none;
  border-bottom: 1px solid #b9b9b9;
  border-radius: 0px;
  font-family: "Arial Nova";
  font-size: 15px;
  padding-left: 10px;
}

.bottom-text-container-color input[type="text"] {
  font-size: 13px;
  display: flex;
  justify-content: center;
  border: 1px solid #b3b1b1;
  border-radius: 5px;
}

.bottom-text-container-color input[type="text"]:focus {
  border: 1px solid #3a3432;
}

.border-input input[type="number"],
.border-input input[type="text"] {
  border: 1px solid #b9b9b9;
}

.profile-form .border-input input[type="text"] {
  font-size: 15px;
  width: 260px;
  margin-bottom: 20px;
  border: 1px solid #b9b9b9;
  padding: 17px 10px;
}

.order-input{
  width: 45%;
}

.order-detail-form input[type="text"] {
  border-bottom: 1px solid #3a3432;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
 
}

.border-input input[type="number"]:focus,
.border-input input[type="text"]:focus {
  border: 2px solid #3a3432;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid #3a3432;
}

.signIn input[type="checkbox"]:before,
.hiden-options-checkbox input[type="checkbox"]:before {
  font-size: 1.2em;
  color: #fff;
  position: absolute;
  left: 1px;
  top: -4px;
}

.signIn input[type="checkbox"],
.hiden-options-checkbox input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
}

.align-buttons{
  align-items: end;
}

input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.5em;
  border: 0.1em solid #3a3432;
  background-color: white;
  outline: none;
  cursor: pointer;
  position: relative;
  top: 5px;
}

.p-inputtextarea:hover {
  border: 1px solid #3a3432;
  box-shadow: none;
}

.p-inputtextarea:focus {
  border: 1px solid #3a3432;
  box-shadow: none;
}

input[type="checkbox"]:checked {
  background-color: #3a3432;
}

input[type="checkbox"]:before {
  content: "\2713";
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  left: 3px;
  top: -4px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.adjust-amount-btn {
  width: 30px;
  border: 1px solid #3a3432;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.end {
  justify-content: flex-end;
}

.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 0px;
}



.order-button {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  color: black;
  justify-content: start;
}

.p-component {
  font-family: "Arial Nova";
  font-size: 17px;
}

button {
  font-family: "Arial Nova";
}

label {
  font-family: "Arial Nova";
}

.p-slider {
  margin-bottom: 10px;
}

.p-slider .p-slider-range {
  background: #3a3432;
}

.p-slider .p-slider-handle {
  border: 2px solid #3a3432;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #eae6e3;
  border-color: #3a3432;
}

.empty-cart-title {
  font-size: 20px;
}

.empty-cart-img {
  height: 300px;
}

.back-arrow {
  border-bottom: 1px solid transparent;
}

.back-arrow:hover {
  border-bottom: 1px solid #3a3432;
}

.custom-spinner {
  border: 5px solid #3a3432; /* Set the color you want */
  border-top: 5px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-slider {
  background: white;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #3a3432;
}

.profile-two-inputs {
  display: flex;
  justify-content: space-between;
}

.order-delivery-info-width {
  width: 50vw;
}

.content-part {
  width: calc(100% - 200px);
}

.order-state-date {
  top: 40px;
}

.left-offset-paid {
  left: calc(33% - 50px);
}

.left-offset-prepared {
  left: calc(66% - 90px);
}

.left-offset-send {
  left: calc(99% - 140px);
}

.order-delivery-state-info {
  font-size: 14px;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.cookies-manager-wrapper{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50000;
  padding: 30px;
}

.cookies-manager{
  width: 500px;
}

.cookies-manager-wrapper button{
  padding: 10px 20px;
  font-size: 15px;
  width:30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-cookies-preferencies{
  width: 700px;
  
}

.cookies-manager-wrapper button.dark{
  background-color: #f0e5d4;
  border: 2px solid #f0e5d4;
  color: #3a3432;
}

.cookies-manager-wrapper button.light{
  background-color: white;
  border: 2px solid #ecd9bc;
  color: #3a3432;
}


.discount-font{
  font-size: 15px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #3a3432;
  background: transparent;
}

.align-add-product{
  justify-content: start;
}

.center-text-profile {
  height: calc(90vh - 80px);
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-height:410px){
  .manage-cookies-preferencies {
    max-height: 400px;
    overflow-y: auto;
    height: 98vh;
  }
}



@media (max-width:1320px){
  .order-detail-form {
    width: 80%;
    
  }
  .order-sumup {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0px;
    width: 80%;
    background-color: #f0e5d4;
  }
}

@media (max-width: 1200px) {
  

  .order-state-description {
    width: 50px;
  }

  .left-offset-prepared {
    left: calc(66% - 40px);
  }

  .left-offset-send {
    left: calc(100% - 65px);
  }

  .order-state-date {
    top: 55px;
  }

  .left-offset-paid {
    left: calc(33% - 40px);
  }
}

@media(max-width:946px){
  

  .profile-navigate {
    display: flex;
    position: static;
    width: 100vw;
    min-height: 0px;
    position: inherit;
    background-color: transparent;
    border-bottom: 2px solid #3a3432;
  }

  .profile-navigate-options {
    display: flex;
    width: 100%;
    gap: "20px";
  }

  .profile-navigate-options {
    position: static;
  }

  .content-part {
    width: 100%;
  }
}

@media(max-width:980px){
  .shopping-cart-item {
    width:90%;
    margin-right: 0px;
  }
  
  .shopping-cart-summary {
    width:90%;
    min-width: 240px;

  }
}

@media (max-width: 850px) {
  .order-delivery-info-width {
    width: 85vw;
  }

  .order-input{
    width: 90%;
  }


  

  .order-summary {
    min-width: 200px;
  }

  .center-text-profile {
    height: calc(90vh - 218px);
  }

  .order-detail-form input[type="text"] {
    border-bottom: 1px solid #3a3432;
  }

  .order-detail-checkbox {
    width: 90%;
  }

  .order-detail-title {
    width: 90%;
  }

  .order-detail-title-wrapper {
    display: flex;
    justify-content: center;
  }

  .order-detail-line {
    justify-content: center;
  }
  .review-send {
    width: 60vw;
  }

  .filter-sidebar {
    display: inline;
  }



 
  .profile-form {
    width: 100%;
  }

  .align-to-end {
    margin-top: 10px;
  }

  .order-summary-wrapper {
    padding: 10px 0px;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    max-width: 80vw;
    overflow-x: auto;
    max-height:none
  }
}

@media (max-width:780px){
  .manage-cookies-preferencies{
    width: 95vw;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 16px;
  }


  .dialog-min-width{
    width: 100vw;
  }

  /* .content-padding {
    padding-top: 70px;
  } */

  .profile-navigate {
    padding: 0px 20px;
  }

  .profile-navigate button {
    font-size: 15px;
  }

  .review-form {
    width: 80vw;
  }

  .dialog-header-title {
    font-size: 15px;
  }
  .dialog-header-icon {
    font-size: 30px;
  }

  .empty-cart-img {
    height: 200px;
  }

  .p-galleria-item-nav {
    top: 36%;
   
  }

  .full-size .p-galleria-item-nav {
    top: 47%;
  }

  .p-galleria-thumbnail-container {
    width: 80vw;
  }

  .more-info {
    margin: 0px;
    margin-left: 20px;
    width: 100%;
  }
}

@media(max-width:691px){
  .align-buttons{
    align-items: start;
  }
}

@media (max-width: 675px) {
 

  .align-packeta-bnt{
    justify-content: start;
    text-align: start;
  }
}

@media (max-width: 660px) {
  .order-detail-form {
    width: 500px;
  }
  body {
    font-size: 15px;
  }
  .review-overview {
    width: 80vw;
  }

  .align-packeta-bnt{
    justify-content: start;
    text-align: start;
  }
}




@media (max-width: 600px) {
  .document{
    width: 100%;
  }
  .cookies-manager{
    width: 100%;
  }
  .profile-two-inputs {
    flex-direction: column;
  }

  .profile-form .border-input input[type="text"] {
    width: 100%;
  }

  .profile-input-row {
    width: 85vw;
  }

  .newsletter-text {
    width: 85vw;
  }


}

@media(max-width: 572px){
  .order-items-specification-width{
    width: 100%;
  }
  .amount-width{
    width: 100%;
  }
  .align-amount{
    justify-content: start;
    text-align: start;
  }

 
}

@media (max-width: 500px) {
  .left-offset-paid {
    left: calc(33% - 20px);
  }

  .order-delivery-state-info {
    font-size: 13px;
  }
  .discount-manager input[type="text"]{
    width: 150px;
  }
}

@media(max-width: 459px){
  .cookies-manager button{
    padding: 10px;
   
  }
  
}

@media (max-width: 420px) {
  body {
    font-size: 14px;
  }

  .cookies-manager button{
    padding: 5px 10px;
   
  }

  .review-send {
    width: 80vw;
  }

  .discount-font{
    font-size: 13px;
  }
}

@media(max-width:415px){
  .m-l-cancel{
    margin-left: 0px;
  }
}

@media (max-width: 450px) {
  .confirmation-width {
    width: 250px;
  }
  .packeta-wraper-width{
    width: 200px;
  }

  .order-sumup {
    min-width: 200px;
    width: 100%;
}
 
}

@media (max-width: 380px) {
  .order-delivery-state-info {
    font-size: 12px;
  }
 
}

@media (max-width: 347px) {
  .cookies-manager button{
   width: 32%;
  }

  .shopping-cart-item {
    margin-right: 0px;
  }
}
