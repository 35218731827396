.navbar{
  height: 80px;
  background-color: white;
  color: #3a3432;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #F0E5D4;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 1000;
  font-weight: bold;
  font-size: 20px;
}

.serach-menu{
  height: fit-content;
  background-color: #eae6e3;
  padding-top: 0px;
}

.serach-menu input[type=text]{
  background-color: #eae6e3;
  width: 100%;
  padding-left: 5px;
  color: #3a3432;
  border-bottom: 2px solid #746c6a;
  margin-left:30px;  
  font-size: 16px;
}

.search-options{
  padding-bottom: 10px;
  font-size: 15px;
  margin-left: 30px;
  margin-right: 25px;
  border: none;
  cursor: pointer;
  color: #6e6765;
  background-color: transparent;
  display: flex;
  justify-content: start;
  text-align: start;
}

.search-options:hover{
  color: #3a3432;
  font-weight: bold;
}

.search-options-wrapper{
  width: 100%;
 
}

.serach-menu input::placeholder{
  color: #746c6a;
}

.shopping-cart-items-count{
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  width: 25px;
  height: 25px;
  top: -20px;
  right: -10px;
  padding-top: 2px;
  border: 2px solid #3a3432;
  border-radius: 50%;
  color: #F0E5D4;
  background-color: #3a3432;
}

.logotext{
  font-size: 30px;
  margin: 5px;
  margin-left: 10px;
}

.logoImge{
  height: 40px;
}

.logoImge:hover{
  cursor: pointer;
}

body, html {
  margin: 0;
  padding: 0;
}

.list{
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  padding: 0px;
}


.listItem {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 18px;

}


.left{
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.middle {
  display: flex;
  align-items: center;
  margin: 0 auto; /* Center the content within the middle section*/
}

.overlay-menu{
  touch-action: none;
  position: fixed;
  top:80px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the last value (alpha) for transparency */
  z-index: 10;
 
}

#scrollable-menu::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}


#scrollable-menu::-webkit-scrollbar-track {
  background: transparent; /* Color of the track */
  border-radius: 10px; /* Optional: round the track corners */
}


#scrollable-menu::-webkit-scrollbar-thumb {
  background: #F0E5D4; /* Color of the scrollbar handle */
  border-radius: 10px; /* Optional: round the handle corners */
}



.white{
  background-color: white;
  border:2px solid white;
}

.yellow{
  background-color: #F0E5D4;
  border:2px solid #F0E5D4;
}

.menu{
 
  position: fixed;
  top:80px;
  left: 0px;
  width: 380px;
  z-index: 100;
  max-height: 100vh;
 
}

.zero-height{
  max-height: 0;
  overflow: hidden;
}


.overflow{
  position: relative;
  top:100px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.menu-overflow{
  position: relative; /* Make the inner element relative */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 80px); /* Set a height for the content that overflows */
}

.menu-wrapper.open {
  left: 0;
}

.menu-item{
  padding: 10px 20px;
  margin-top: 9px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: start;
  }

  .navbar ul{
    margin: 0px;
    padding: 0px;
  }
  

/* DropdownMenu.css */
.dropdown {
    position: relative;
    display: inline-block;
    z-index: 500;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    transform: translateX(-90%);
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 3000;
    padding: 10px;
    width: 250px;
  }

  .login-dropdown{
    left:15px;
  }

  .choosen-items{
    max-height: 380px; /* Adjust the maximum height as needed */
    overflow-y: auto;
  }

  .dropdown-menu-text{
    color: #3a3432;
    padding-left: 5px;
    font-weight: normal;
    font-size: 17px;
  }

  .search{
    font-size: 23px;
    
  }

  .btn{
    border-radius: 5px;
  }

  .navbar-items{
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;

  }

  .custom-vector{
    height: 30px;
  }

  .btn.dark.dropdown-btn{
    padding: 10px 10px;
    font-size: 18px;
    font-weight: normal;
  }

  .right{
    display: flex;
    align-items: center;
  }

  .custom-vector, .search,  .hearth-vector{
    padding-left: 7px;
    padding-right: 7px;
  }

  .right-icons{
    width: 39px;
    height: 29px;
  }

  .menu-bars-size{
    font-size: 25px;
  }

  
  @media (max-width: 768px) {
    .list{
      padding-left: 0px;
      }
  

    .dropdown-menu{
      max-width: 300px;
    }

    .list{
      padding-right: 10px;
    }

    .custom-vector{
      height: 27px;
    }

    .menu-bars-size{
      font-size: 20px;
    }

    .search{
      font-size: 20px;
    }

    .right-icons{
      height: 25px;
      width: auto;
    }

    .overlay-menu{  
    top:70px;
    }

    .left{
      padding: 0px;
    }

    .navbar-menu{
      display: none;
    }

    .menu-button-login {
      font-size: 15px;
     
  }

    .navbar{
      height: 70px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }

    .menu-overflow{
      max-height: calc(100vh - 70px);;
    }

    .menu{
      top:70px;
    }

  
    .logoImge{
      height: 30px;
    }

    .middle{
      align-items: start;
      justify-content: start;
      text-align: start;
      margin-left: 0px;
    }

    .navbar-items{
      padding-left: 2px;
      padding-right: 2px;
      margin-left: 0px;

    }

    .dropdown-menu-text {
      font-size: 15px;
  }
  .btn.dark.dropdown-btn{
    font-size: 15px;
    padding: 10px 5px;
  }
  .menu-wrapper {
    top:80px;
  }
  }

  @media (max-width: 480px) {
    
      .menu-item{
        padding: 7px 10px;
        margin-top: 0px;
        font-size: 14px;
        width: 100%;
        }

        .menu{
        
          width: 100%;
       
        }
      }

  @media(max-width:340px){
    .dropdown-menu{
      max-width: 290px;
    }

    .logoImge{
      height: 27px;
    }
  }