.slide {
  width: 100%;
  height: 100%;

}
  
  .slide img {
    width: 100%;
    height: 600px;
    object-fit: cover; /* Ensure the image covers the entire container */
    object-position: center; /* adjust the third value to control the offset from the top */

  }

  .background{
    z-index: -1;
  }

  .img-product-hp{
    width: 800px; /* or your desired width */
    height: 350px; /* or your desired height */
    object-fit: cover; /* or contain, depending on your needs */
    object-position: center; /* adjust the third value to control the offset from the top */
  }

  .img-hp-container{
    margin:30px;
  }


  .horizontal-flip {
    transform: scaleX(-1);
  }

  .icons-hp img{
    height: 40x;
    width: 40px;
  }


  .hp-img-row{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .hp-text-container {
    padding: 50px 0px;
    display: flex;
    flex: wrap;
    justify-content: center;
    align-items: center;
   
    max-width: 1660px; 
  }

  .max-hp-width{
    width: 1660px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #3a3432;
   
}

.hp-description{
  width: 50vw;
  height: 200px;
}

.hp-description textarea {
  width: 100%; 
  height: 100%; 
}


.description-img{
  width: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
  

  .btn-newsletter{
    color:black;
    border-color: transparent;
    background-color: #b3b1b1;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
  }

  .btn-newsletter:focus{
    border-color: transparent;
  }

  .white-space{
    height: 60px;
  }

  .bottom-text-container-color{
   background-color: #f0e5d4;
   width: 250px;
   height: 147px;
   padding: 20px 30px;
   border-radius: 5px;
    
  }

.hp-text-img {
    height: 350px; 
    width: 300px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    
  }

  .ig-slider{
    max-width: 1660px;
  }

  .hp-title{
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }

  .review-container{
    background-color: #f0e5d4;
    border-radius: 10px;
    padding: 20px 30px;
    height: 250px;
  }

  .max-hp-width-bottom{
    width: 800px;
    max-width: 800px;
  }

  .bottom-text-container{
    width: 350px;
  }
  .contact-row-img{
    display: flex;
    justify-content: center;
    width: 30px;
    margin-bottom: 5px;
  }
  .contact-row-text{
    display: flex;
    margin-bottom: 5px;
  }

  .right-bottom-container{
    margin-right: 32px;
  }

  .left-bottom-container{
    margin-left: 32px;
  }

  .contact-row{
    display: flex;
    margin: 0px;
    padding: 0px;
    width: 180px;
    align-items: center;
  }

  .review-comment{
    margin-top: 15px;
    overflow: auto;
    padding-right: 10px;
    height: 110px;
}

.review-author{
  opacity: 0.7;
  margin-top: 10px;
}


.image-container {
    position: relative;
    display: inline-block;
}



.post-captions{
  font-size: 17px;
}
.ig-post {
  font-size: 15px;
  width: 385px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  
}


.post-image{
  width: 385px;
  max-width: 385;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}
.overlay-hp {
    position: absolute;
    left: 0;
    width: fit-content;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0); /* Transparent initially */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
    z-index: 2;
}

.post-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    visibility: hidden;
    z-index: 5; /* Ensure it's above the overlay */
}

.post-text div{
  transform: translate(0, 8%);
  font-size: 20px;
}

.image-container:hover .overlay {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
    opacity: 1; /* Visible on hover */
}

.ig-post:hover .post-text {
    visibility: visible; /* Visible on hover */
}

.ig-post:hover .post-image, .post-image:hover {
    filter: brightness(70%); /* Adjust brightness as needed */
    transition: filter 0.3s ease;
}

.ig-post div {
    margin-top: 3px;
}


  .hp-text{
    min-width: 350px;
    font-size: 18px;
    width: 80%;
  }

  .ig-icon{
    height: 40px;
    width: 40px;
  }

 

  @media (max-width: 2000px){
    .img-product-hp {
      width: 600px;
      height: 300px;
    }

    .description-img{
      width: 30%;
    }

    .hp-text{
      width: 70%;
    }

    .max-hp-width{
      width: 1260px;
    }
    
  .max-hp-width-bottom{
    width: 600px;
  }

    .ig-post {
      width: 285px;

    }
    .post-image{
      width: 285px;
      height: 250px;
    }

    .slide img {
      height: 500px;
    }

   
   
  }

  @media (max-width: 1450px){
    .img-product-hp {
      width: 500px;
      height: 250px;
    }

    .max-hp-width-bottom{
      width: 500px;
    }

    .max-hp-width{
      width: 1060px;
    }

    .ig-post {
      width: 235px;
      margin-right: 20px;

    }
    .post-image{
      width: 235px;
      height: 200px;
    }

    
   
  }

  @media (max-width: 1220px){
    .img-product-hp {
      width: 400px;
      height: 200px;
    }

    .description-img{
      width: 40%;
    }

    .hp-text{
      width: 50%;
    }

    .slick-dots li button:before {
      font-size: 9px;
      color: #3a3432;
     
  }

  .max-hp-width-bottom{
    width: 390px;
   
  }

  .review-container{
    height: 200px;
    padding: 10px 20px;
  }
  .review-comment{
    height: 70px;
  }

  .review-comment{
    margin-top: 15px;
  }

    .img-hp-container{
      margin:20px;
    }

    .max-hp-width{
      width: 840px;
    }

    .ig-post {
      width: 265px;

    }
    .post-image{
      width: 265px;
      height: 230px;
    }

    .slide img {
      height: 400px;
    }
   
  }



  @media (max-width: 920px){
    .img-product-hp {
      width: 340px;
      height: 170px;
    }

  

    .max-hp-width-bottom{
      width: 100%;
    }

    .right-bottom-container{
      margin-right: 0px;
    }
  
    .left-bottom-container{
      margin-left: 0px;
    }

    .white-space{
      height: 0px;
    }

    .max-hp-width{
      width: 720px;
    }

    .post-captions{
      font-size: 15px;
    }

    .description-img{
      width: 100%;
    }

    .hp-text-img{
      margin-bottom: 30px;
      margin-right: 0px;
        width: 250px;
        height: 300px;
      
    }



    .hp-text{
      text-align: center;
      width: 100%;
    }

    .hp-text-container{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }


   

  
   
  }

  @media (max-width: 830px){
    .img-product-hp {
      width: 300px;
      height: 150px;
     
    }

    

    .max-hp-width{
      width: 580px;
    }

    .hp-text{
      font-size: 16px;
    }
   

    .img-hp-container{
      margin: 20px 10px;
    }
   
  }

  @media(max-width: 720px){
    .hp-text-container{
      flex-direction: column;
    }

   

    .slide img {
      height: 300px;
    }
    .hp-text-img{
        width: 200px;
        height: 250px;
      
    }
  }

  @media (max-width: 690px){
    .img-product-hp {
      width: 280px;
      height: 140px;
    }
  }

  @media (max-width: 630px){
    .img-product-hp {
      width: 240px;
      height: 120px;
    }
  }

  

  @media (max-width: 650px){
    .max-hp-width{
      width: 420px;
    }

    .ig-icon{
      height: 30px;
      width: 30px;
    }

    .post-text div{
      font-size: 16px;
    }
  
    .ig-post {
      width: 200px;
    }
    .post-image{
      width: 200px;
      height: 165px;
    }

    .hp-text-img{
      width: 170px;
      height: 220px;
    
  }

  }

  @media (max-width: 600px){
   

    .hp-title {
      font-size: 17px;
  }
  }

  @media (max-width: 550px){
    .img-product-hp {
      width: 200px;
      height: 100px;
    }

    .contact-small{
      width: 180px;
    }
  }

  @media (max-width: 490px){
   

    .slide img {
      height: auto;
      max-height: 200px;
    }

    .max-hp-width{
      width: 360px;
    }

    .ig-post {
      width: 250px;
    }
   

    .post-image{
      width: 170px;
      height: 135px;
    }
   
  }

  @media (max-width: 470px){
    .hp-text-img{
      width: 150px;
      height: 200px;
    
  }
  .img-product-hp {
    width: 170px;
    height: 85px;
  }

      .post-image{
        width: 200px;
        height: 165px;
      }
    

    .max-hp-width{
      width: 300px;
    }

    .hp-text{
      min-width: 200px;
    }


   
  }


  @media (max-width: 400px){
    .max-hp-width{
      width: 250px;
    }
    .img-product-hp {
      width: 130px;
      height: 65px;
    }
   
  }