.overviews{
    padding: 0px;
    height: 100%;
    display: flex;
  }

  .sidebar-title{ 
    font-weight: bold;
    color: #3a3432;
    font-size: 18px;
  }

  .filter-menu-option{
    padding-bottom: 10px;
    border-bottom: 1px solid #ced4da;
    width: 100%;
  }

  .add-form-label{
    width: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
   
  }
  .p-datatable .p-datatable-tbody > tr > td {
  
    font-size: 15px;
}
  .dialog-added-to-cart{
    width: 570px;
  }
  .add-product-form{
    display: flex;
    justify-content: center;
    font-size: 15px;
  }
  
  .add-product-form .input-row{
    max-width: 700px;
  
  }

  .create-giftcard-wrapper{
    min-height: 75vh;
  }
    


  .add-product-card{
    
    justify-content: center;
    padding: 20px;
    box-shadow: 0 0px 10px #3a3432;
    height: auto;
    margin: 30px 0px;
  }

  .scroll-menu{
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
    padding: 20px; /* Optional padding for content */
  }

  #scrollable-container::-webkit-scrollbar-track {
    width: 10px; /* Adjust width as needed */
    background-color: transparent; /* Scrollbar track background color */
    border-radius: 10px; /* Rounded corners for the track */
   
  }



  #scrollable-container::-webkit-scrollbar-thumb {
    background-color: #c9bba6; /* Adjust thumb color (optional) */
    border-radius: 10px; /* Rounded corners for the thumb (optional) */
  }

  

  .filter-menu{
    display: inline;
    position: fixed;
    width: 250px;
  }

  .product-detail-image img{
    width: 600px;
    object-fit: cover;
    
  }

  .product-detail-content .product-detail-image img{
    height: 800px;
  }

  .input-price-range-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
  }

  .input-price-range{
    width: fit-content;
    max-width: 56px;
    padding: 10.5px;
    margin: 5px 0px;
    border: 1px solid #eae6e3;
  }
  

  .filter-menu-box{
    margin-right: 10px;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 5px;
    font-size: 15px;
    padding-left: 8px;
    padding-right: 8px;

  }
  
  .filter-menu-box:hover{
    cursor: pointer;
  }

  .filter-menu .input-row{
    display: flex;
    width: 100%;
    justify-content: start;
   
  }

  .hiden-filter-menu .btn{
    width: 100%;
    padding: 20px;
    font-size: 16px;
    border: none;
    color:black;
    background-color: transparent;
  }

  .filter-menu-title{
    padding-bottom: 20px;
    padding-left: 5px;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: start;
   
  }

  .filter-menu-wrapper{
    width: 330px;
    min-height: calc(100vh - 80px);
    background-color: #f0e5d4;
    display: flex;
    padding-left: 60px;
    flex-direction: column;
  }

  .filter-menu input[type=checkbox] {
    border-radius: 10px;
    top: 7px;
    border: 0.15em solid #3a3432;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .filter-menu input[type=checkbox]:before {
    font-size: 1.0em;
    padding-top: 2px;
    padding-bottom: 1px;
    top: -3,5px;
  }

  .slider {
    width: 200px;
  }


  .product-overviews{
    width: calc(100% - 337px);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  
.filter-btn{
    display: none;
 }

 .product-detail-dropdown-menu .order-button{
  font-size: 15px;
  color: black;
 }

 .product-detail-dropdown-menu{
  position: absolute;
  background-color: white;
  z-index: 10;
  border-radius: 5px;
  margin-top: 2px;
  border-radius: 5px;
  box-shadow: 0 0px 5px #3a3432;
}

  .order-dropdown-menu{
    position: absolute;
    width: 160px;
    background-color: white;
    z-index: 10;
    border-radius: 5px;
  }

  .order-button:hover{
    background-color: #f0e5d4;
  }
  

  

  .overview-card-title{
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
 
  .order-dropdown-wrapper{
    position: relative;
  }

  .product-detail-container {
    display: none;
    flex-direction: column;
    height: calc(100vh-100px);
    margin-top: 50px;
  }

  .product-detail-specification{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    min-width:25%;
    max-width: 35%;
    justify-content: start;
  }

  .available-amount{
    margin-left:10px;
  }

  .overview-imgs-wrapper{
    max-width: 700px;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: start;
  }
  .table-container {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s ease-in-out;

  }
  
  .table-container.open {
    max-height: 1000px; 
  
  }

  .specification-btn{
    color:black;
    background-color: transparent;
    width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3a3432;
    font-size: 17px;
    padding-bottom: 10px;
  }
  
  .specification-btn-plus{
    font-size: 20px;
  }

  .variant-form {
    border-bottom: 2px solid #3a3432;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  
  .option{
    width: fit-content;
    padding: 5px 10px;    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 30px;
  }

  .prodduct-overview{
    width: 33%;
    height: calc(33% + 120px);
  }
  
  .card-image-default{
    transition: opacity 0.4s ease-in-out; 

  }

  .card-image {
    border-radius: 15px;
    object-position: center;
    object-fit: cover;
  }

  .card-image.default {
    opacity: 1;
  }
  
  .card-image.alter {
    opacity: 0;
  }


  .card-grid {
    max-width: 990px;
    display: grid;
    gap: 10px;  /* Adjust gap between cards */
  }

  .multiple-options{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
  .overview-img{
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .product-attribute-title{
    width: 100px;
    font-weight: bold;
    display: flex;
    margin-top: 10px;
  }

  .product-detail-specification li{
    list-style: none;
  }
  
  .product-detail-specification ul{
    padding: 0px;
  }

  .product-detail-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .dialog-add-to-cart-btn{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    border: 2px solid #3a3432,
  }

  .product-choose-option h4{
    margin: 0px; 
  }

  .price-size{
    font-size: 25px;
  }

  .product-choose-option p{
    display: flex;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  
  .product-choose-option{
    display: flex;
  }

  .dropdown-product-detail{
    width: 100%;
    border:none;
    border-bottom: 1px solid #3a3432;
    box-sizing: border-box;
  }
  
  .dropdown-product-detail:hover{
    border: none;
    border-bottom: 1px solid #3a3432;
  }

  .product-detail .p-dropdown .p-dropdown-label {
    padding: 10px 0px;
  }

  .block {
    border: 1px solid #c7c9ca;
    border-radius: 5px;
    padding: 10px;
    margin: 0px;
    opacity: 0.8;
    margin-top: 10px;
    align-items: center;
    font-size: 15px;
    width: 160px;
  }

  .productTitle{
    color:#3a3432;
    font-size: 40px;
    font-weight: bold;
  }

  .item-added-overview p{
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    margin-bottom: 3px;
    font-size: 13px;
  }

  .hearth-wishlist{
    height: 70px;
  }



  @media(max-width:360px){
    .available-amount{
      margin-left: 0px;

    }
  }
  

  @media(max-width:1170px){
    .product-detail-content{
      margin: 0px;
      padding: 20px;
    }
    .product-detail-specification{
      width: 75%;
      min-width: none;
      max-width: none;
      margin: 0px;
    }

    .productTitle{
      margin-top: 30px;
    }

    .hearth-wishlist{
      margin-top: 30px;
    }
  }
  
  @media(max-width:600px){
    .product-detail-specification{
      width: 95%;
    }
  }
  
  @media (max-width: 410px) {
    .overview-img{
      width: 60px;
      height: 50px;
    }

    
   }

  @media(max-width:768px){
    .product-detail-container{
      margin-top: 40px;
      width: 100%;
    } 

    .add-product-card{
    
      box-shadow:none;
    
    }


    .hearth-wishlist{
      height: 50px;
    }
    .dialog-added-to-cart{
      width: 100%;
    }

    .productTitle{
      font-size: 25px;
    }

    .price-size{
      font-size: 18px;
    }

    .product-detail-content .product-detail-image img{
      height: 60vw;
    }
    
  .product-detail-image img{
    height: 60vw;
    width: 100%;    
  }

  .product-detail-image{
    height: 60vw;
    width: 100%;
    margin-left: 0px;
  }
  
  .overview-img{
    width: 100px;
    height: 80px;
  }

  .product-detail-content{
    margin-bottom: 0px;
    width: 100%;
  }

  .product-detail-specification{
    width: 80%;
  }
  }


  @media(max-width:905px) {
    .filter-menu{
        display: none;
      }

     

      .upper-options-wrapper{
        width: 720px;
      }

      .filter-menu-wrapper{
        display: none;
      }

   

      .filter-btn{
        display: flex;
        justify-content: end;
      }

      .product-overviews{
        width: 100%;
        min-height: 80vh;
       
      }
    
    
    
  }

  @media (max-width:632px){
    .variant-form input[type=text],.variant-form input[type=number]{
      width: 300px;
    }

    .align-add-product{
      justify-content: center;
    }
  }



@media (max-width:545px){
 
  .upper-options-wrapper{
    width: 100%;
  } 

  

  .overview-card-title, .order-button{
    font-size: 15px;
  }

  .order-dropdown-menu{
    width: 160px;

  }

  .block{
    width: 160px;
    font-size: 15px;
    height: 35px;
  }
  .filter-btn{
    font-size: 15px;
    width: 120px;
    height: 35px;

  }
 


  
 
}

@media(max-width:450px){

  .filter-btn, .order-button, .block, .overview-card-title,.product-detail-dropdown-menu .order-button{
    font-size: 14px;

  }

  .order-dropdown-menu{
    width: 150px;

  }

  .block{
    width: 150px;
  }

  .variant-form input[type=text],.variant-form input[type=number]{
    width: 230px;
  } 

  .overview-img {
    width: 80px;
    height: 60px;
}

}


@media(max-width:400px){
  .alert-max-width{
    max-width: 150px;
  }
  .filter-btn, .order-button, .block, .overview-card-title,.product-detail-dropdown-menu .order-button{
    font-size: 13px;

  }

  .variant-form input[type=text],.variant-form input[type=number]{
    width: 190px;
  }

  .overview-img {
    width: 65px;
    height: 45px;
}


}

@media (min-width: 546px) and (max-width: 805px) {
  .product-overviews-row {
    width: 480px;
  }

  .upper-options-wrapper{
    width: 480px;
  }
}

/* Styles for screens up to 1200px */
@media (min-width: 906px) and (max-width: 1200px) {
  .product-overviews-row {
    width: 480px;
  }

  .upper-options-wrapper{
    width: 480px;
  }
}

/* Styles for screens up to 1500px */
@media (min-width: 1201px) and (max-width: 1500px) {
  .product-overviews-row {
    width: 720px;
  }
  .upper-options-wrapper{
    width: 720px;
  }
}

/* Styles for screens up to 1950px */
@media (min-width: 1501px) and (max-width: 1950px) {
  .product-overviews-row {
    width: 960px;
  }
  .upper-options-wrapper{
    width: 960px;
  }
}

/* Styles for screens larger than 1950px */
@media (min-width: 1951px) {
  .product-overviews-row {
    width: 1440px;
  }

  .upper-options-wrapper{
    width: 1440px;
  }
}