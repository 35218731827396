.form{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form{
 margin: auto;
}

.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  background-color: white;
  text-align: center;
  font-size: 17px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
  
}

.missing-input {
  color: #df4930;
  width: 220px;
  font-size: 15px;
  text-align: left;
}


.line{
  height:0.5px;
  width: 100%;
  background-color:  #3a3432;
  position: relative;
 
}

.circle{
  position: absolute;
  top: -18px;
  left: calc(50% - 20px);
  width: 36px;
  border: 2px solid #3a3432;
  height: 36px;
  border-radius: 20px;
  background-color: #F0E5D4;
}

.loginButtons-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginButton{
  width: 100%;
  min-height: 50px;
  color:  white;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.center-container {
  display: flex;
  height: calc(90vh - 150px);
  justify-content: center;
  align-items: center;
}

.google{
  border: none;
  background-color: #df4930;
  border-radius: 5px;
  width: 211.59px;
}

.facebook{
  background-color: #507cc0;
  border:none;
  border-radius: 5px;
  width: 211.59px;
}


.icon{
  margin-right: 10px;
  padding-top: 2px;
}

input[type="text"].input-login,input[type="password"].input-login {
  height: 40px;
  width: 92%;
  margin-top: 10px;
}

.password-container {
  position: relative;
  width: 100%;

}

.password-toggle {
  position: absolute;
  top: 65%; /* Center vertically within the parent */
  right: 0px;
  transform: translateY(-50%); /* Center vertically within the parent */
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 10px;
}

.password-toggle:focus {
  outline: none;
}

/* Styles for the eye icon */
.password-toggle svg {
  font-size: 1.2em;
  color: #ccc; /* Adjust the color as needed */
}

.submit{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 220px;
  height: 40px;
}

.reset-password-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  padding: 70px 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reset-password-form-text{
  width: 300px;
}

.reset-password-form.show{
  display: flex;
}

.reset-password-form input{
  width:100%;
}

.success{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #eae6e3;
  border: 5px solid #3a3432;
  color: #3a3432;
  font-size: 20px;
  padding: 50px;
}

.unsuccess{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #3a3432;
  color: #eae6e3;
  font-size: 20px;
  border: 5px solid #eae6e3;
  padding: 50px;
 
}


@media (max-width: 1300px) {

  .wrapper{
    width:50%;
    min-width: 300px;
  }
  .login{
    margin-top: 20px;
  }
 

}

@media(max-width: 600px){
  .wrapper{
    width: 80%;
    min-width: 300px
  }

  .unsuccess{
    position: fixed;
    top: 100px;
    width: 100%;
    box-shadow: none;
    left: 0;
  }

  .success{
    position: fixed;
    top: 100px;
    width: 100%;
    box-shadow: none;
    left: 0;
  }

  .reset-password-form {
    position: fixed;
    top: 100px;
    width: 100%;
    box-shadow: none;
    left: 0;
    padding: 50px; /* Adjust padding for smaller screens */
  }
  
  .reset-password-form-text{
    width: 100%;
    min-width: 250px
  }

  .center-container {
    transform: none;
  }

}
@media (max-height: 660px) {  
  .form-wrapper { 
    display: inline;
} }


@media(max-width:460px){
  .form-wrapper { 
   
    font-size: 16px;
    display: inline;
  
  }
  
  .loginButton {
    min-height: 40px;
    font-size: 16px;
   
}
}



@media (max-width: 1500px) {
  .line{
    width: 100%;
  }
}
.loginTitle{
  top: 20px;
  color:#3a3432;
  margin: 5px 0px;
  
}

.link{
  color: inherit;
  text-decoration: none;
}

.register-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
 
}

.overlay {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the last value (alpha) for transparency */
  z-index: 1001;
}




.switch-form{
  color:#3a3432;
  cursor: pointer;
  text-decoration: underline;
  margin: 10px 0px;
}

.switch-form:hover{
 font-weight: bold;
}

.checkbox{
  margin-top: 10px;
  margin-bottom: 10px;
}